<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-back-button text="" slot="start" :defaultHref="{name: 'home'}" />

				<ion-title>
					<ion-label v-if="theme">{{ theme.name }}</ion-label>
				</ion-title>
				
				<div slot="end" class="user-wrapper" @click="$router.push(isLoggedIn ? `/profile/${user.id}` : '/login')">
					<p class="web-version">{{ isLoggedIn ? 'Profil' : 'Prihlásiť sa' }}</p>
					<ion-icon :icon="isLoggedIn ? personCircleOutline : logInOutline"></ion-icon>
				</div>
			</ion-toolbar>
		</ion-header>
		<ion-content>

			<div class="container px-1 mt-4">
				<ion-button>Pridať projekt</ion-button>
				<h2 class="mb-3 mt-5">Moje projekty</h2>
			</div>
			<ion-row class="container mb-3">
				<ion-col size="12" size-sm="6" size-lg="4">
					<ion-card>
						<img  class="thumbnail" src="https://s3.eu-central-1.amazonaws.com/gamisfera.hemisfera.sk/uploads/public/60b/553/6ef/60b5536ef40e3010389369.png">
						<div class="description">
							<h3>Level generátor</h3>
							<ion-chip class="mt-1 mb-2">asdasdas</ion-chip>
							<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quaerat, tempora earum? Sequi quos dolores perferendis, eos aperiam vitae ex, facilis velit fugiat unde adipisci odit, rerum ab dolore quisquam minus?</p>
							<div class="autor d-flex ion-align-items-center mt-2 mb-2">
								<img src="https://s3.eu-central-1.amazonaws.com/gamisfera.hemisfera.sk/uploads/public/60f/94d/e2e/60f94de2e000a422187685.jpg" alt="">
								<p>erwerwer</p>
							</div>
							<p class="approval">Čaká sa na schválenie</p>
						</div>
					</ion-card>
				</ion-col>
				<ion-col size="12" size-sm="6" size-lg="4">
					<ion-card>
						<img  class="thumbnail" src="https://s3.eu-central-1.amazonaws.com/gamisfera.hemisfera.sk/uploads/public/60b/553/6ef/60b5536ef40e3010389369.png">
						<div class="description">
							<h3>Level generátor</h3>
							<ion-chip class="mt-1 mb-2">asdasdas</ion-chip>
							<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quaerat, tempora earum? Sequi quos dolores perferendis, eos aperiam vitae ex, facilis velit fugiat unde adipisci odit, rerum ab dolore quisquam minus?</p>
							<div class="autor d-flex ion-align-items-center mt-2 mb-2">
								<img src="https://s3.eu-central-1.amazonaws.com/gamisfera.hemisfera.sk/uploads/public/60f/94d/e2e/60f94de2e000a422187685.jpg" alt="">
								<p>erwerwer</p>
							</div>
							<p class="approval">Čaká sa na schválenie</p>
						</div>
					</ion-card>
				</ion-col>
				<ion-col size="12" size-sm="6" size-lg="4">
					<ion-card>
						<img  class="thumbnail" src="https://s3.eu-central-1.amazonaws.com/gamisfera.hemisfera.sk/uploads/public/60b/553/6ef/60b5536ef40e3010389369.png">
						<div class="description">
							<h3>Level generátor</h3>
							<ion-chip class="mt-1 mb-2">asdasdas</ion-chip>
							<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quaerat, tempora earum? Sequi quos dolores perferendis, eos aperiam vitae ex, facilis velit fugiat unde adipisci odit, rerum ab dolore quisquam minus?</p>
							<div class="autor d-flex ion-align-items-center mt-2 mb-2">
								<img src="https://s3.eu-central-1.amazonaws.com/gamisfera.hemisfera.sk/uploads/public/60f/94d/e2e/60f94de2e000a422187685.jpg" alt="">
								<p>erwerwer</p>
							</div>
							<p class="approval">Čaká sa na schválenie</p>
						</div>
					</ion-card>
				</ion-col>
				<ion-col size="12" size-sm="6" size-lg="4">
					<ion-card>
						<img  class="thumbnail" src="https://s3.eu-central-1.amazonaws.com/gamisfera.hemisfera.sk/uploads/public/60b/553/6ef/60b5536ef40e3010389369.png">
						<div class="description">
							<h3>Level generátor</h3>
							<ion-chip class="mt-1 mb-2">asdasdas</ion-chip>
							<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quaerat, tempora earum? Sequi quos dolores perferendis, eos aperiam vitae ex, facilis velit fugiat unde adipisci odit, rerum ab dolore quisquam minus?</p>
							<div class="autor d-flex ion-align-items-center mt-2 mb-2">
								<img src="https://s3.eu-central-1.amazonaws.com/gamisfera.hemisfera.sk/uploads/public/60f/94d/e2e/60f94de2e000a422187685.jpg" alt="">
								<p>erwerwer</p>
							</div>
							<p class="approval">Čaká sa na schválenie</p>
						</div>
					</ion-card>
				</ion-col>
				<ion-col size="12" size-sm="6" size-lg="4">
					<ion-card>
						<img  class="thumbnail" src="https://s3.eu-central-1.amazonaws.com/gamisfera.hemisfera.sk/uploads/public/60b/553/6ef/60b5536ef40e3010389369.png">
						<div class="description">
							<h3>Level generátor</h3>
							<ion-chip class="mt-1 mb-2">asdasdas</ion-chip>
							<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quaerat, tempora earum? Sequi quos dolores perferendis, eos aperiam vitae ex, facilis velit fugiat unde adipisci odit, rerum ab dolore quisquam minus?</p>
							<div class="autor d-flex ion-align-items-center mt-2 mb-2">
								<img src="https://s3.eu-central-1.amazonaws.com/gamisfera.hemisfera.sk/uploads/public/60f/94d/e2e/60f94de2e000a422187685.jpg" alt="">
								<p>erwerwer</p>
							</div>
							<p class="approval">Čaká sa na schválenie</p>
						</div>
					</ion-card>
				</ion-col>
				<ion-col size="12" size-sm="6" size-lg="4">
					<ion-card>
						<img  class="thumbnail" src="https://s3.eu-central-1.amazonaws.com/gamisfera.hemisfera.sk/uploads/public/60b/553/6ef/60b5536ef40e3010389369.png">
						<div class="description">
							<h3>Level generátor</h3>
							<ion-chip class="mt-1 mb-2">asdasdas</ion-chip>
							<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quaerat, tempora earum? Sequi quos dolores perferendis, eos aperiam vitae ex, facilis velit fugiat unde adipisci odit, rerum ab dolore quisquam minus?</p>
							<div class="autor d-flex ion-align-items-center mt-2 mb-2">
								<img src="https://s3.eu-central-1.amazonaws.com/gamisfera.hemisfera.sk/uploads/public/60f/94d/e2e/60f94de2e000a422187685.jpg" alt="">
								<p>erwerwer</p>
							</div>
							<p class="approval">Čaká sa na schválenie</p>
						</div>
					</ion-card>
				</ion-col>
			</ion-row>


			<!-- <ion-row class="ion-justify-content-center mb-3">
				<ion-card>
					<div class="description">
						<h3>Level generátor</h3>
						<ion-chip>asdasdas</ion-chip>
						<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quaerat, tempora earum? Sequi quos dolores perferendis, eos aperiam vitae ex, facilis velit fugiat unde adipisci odit, rerum ab dolore quisquam minus?</p>
						<div class="autor d-flex ion-align-items-center">
							<img src="https://s3.eu-central-1.amazonaws.com/gamisfera.hemisfera.sk/uploads/public/60f/94d/e2e/60f94de2e000a422187685.jpg" alt="">
							<p>erwerwer</p>
						</div>
						<p class="approval">Čaká sa na schválenie</p>
					</div>
				</ion-card>
			</ion-row> -->

		</ion-content>
	</ion-page>
</template>

<script>
import { personCircleOutline, logInOutline } from 'ionicons/icons'
import { mapGetters} 	from 'vuex'

export default {
	data() {
		return {
			personCircleOutline,
			logInOutline
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn',
			'user'
		]),
	},
}
</script>

<style lang="sass" scoped>
ion-content
	--padding-start: 10px
	--padding-end: 10px

ion-button
	max-width: 100%
	--border-radius: 50px
	margin-left: 50%
	transform: translateX(-50%)

ion-col
	--ion-grid-column-padding: 15px

ion-card
	box-shadow: none
	border: 1px solid rgba(0, 0, 0, .1)
	transition: .2s
	padding: 0

	&:hover
		box-shadow: 0px 2px 12px 0px lightgray

	.thumbnail
		width: 100%
		height: 230px
		object-fit: cover

	.description
		width: 100%
		padding: 30px 30px 20px

		.autor
			p
				font-weight: 300
				margin: 0

			img
				height: 25px
				margin-right: 10px
				border-radius: 50px
		
@media only screen and (max-width: 500px)
	.container
		padding: 0

	ion-searchbar
		margin: 0 auto 50px
		width: 90%

@media only screen and (max-width: 1199px)
	.container
		max-width: 1000px !important

@media only screen and (max-width: 992px)
	.container
		max-width: 700px !important

</style>